function Greeting_message_assign_action(props) {
  const { answer, lang, selectAdmin, selectTeam, assignList } = props;

  React.useEffect(() => {
    setAssignData();
  }, []);

  const setAssignData = () => {
    if (answer.assign_nexus_user_id) {
      answer.assignData = assignList.agents[answer.assign_nexus_user_id];
    } else if (answer.assign_team_id) {
      answer.assignData =
        assignList.teams[answer.assign_team_id] ||
        assignList.teams["reception"];
    } else {
      answer.assignData = assignList.teams["reception"];
    }
  };

  const [selectTransfer, setSelectTransfer] = useState(answer.transferActive);

  const [selectChatbot, setSelectChatbot] = useState(answer.chatbotActive);

  let actionList = [
    {
      action: "default",
      text: "__greeting_add_to_team",
    },
    {
      action: "assign",
      text: "__greeting_add_to_user",
    },
  ];

  const selectAction = (action) => {
    props.selectAction(action);
  };

  const toggleTransfer = (action) => {
    setSelectTransfer(action);
    props.toggleTransfer(action);
  };

  const toggleChatbot = (action) => {
    setSelectChatbot(action);
    props.toggleChatbot(action);
  };

  return (
    <div class="sp-row">
      <div
        class="sp-col answer-label -align-top"
        style={{ marginRight: "0px" }}
      >
        <span class="sp-text">{lang.__greeting_actions}</span>
        <div class="sp-row" style={{ alignItems: "flex-start", marginTop: 16 }}>
          <span
            class="sp-checkbox"
            style={{
              marginTop: 4,
              height: 22,
              width: 60,
              justifyContent: "center",
              marginRight: 0,
            }}
          >
            <input
              class="sp-input"
              type="checkbox"
              checked={selectTransfer}
              onClick={() => toggleTransfer(!selectTransfer)}
            />
          </span>
          <span
            class="sp-text"
            style={{ marginTop: 4, width: "30%", flexShrink: 0 }}
          >
            {lang.__setting_chat_transfer}
          </span>
          <div class="sp-col">
            <div class="dropdown sp-dropdown">
              <div
                class="dropdown-button dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <div class="text">{lang[props.selectedAction]}</div>
                <i class="icon-sort-down"></i>
              </div>
              <div class="dropdown-menu sp-list">
                {actionList.map((item) => {
                  return (
                    <div
                      class="item -clickable sp-row -gap"
                      onClick={() => selectAction(item.action)}
                      key={item.action}
                    >
                      <div class="sp-col">
                        <p class="sp-text">{lang[item.text]}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {/* Agents */}
            {answer.action === "assign" && (
              <div style={{ marginTop: 8 }} class="dropdown sp-dropdown">
                <div
                  class="dropdown-button dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  <div class="text">
                    {answer.assignData && answer.assignData.displayname} (
                    {answer.assignData && answer.assignData.username})
                  </div>
                  <i class="icon-sort-down"></i>
                </div>
                <div class="dropdown-menu sp-list">
                  <div>
                    {assignList &&
                      _.values(assignList.agents).map((item, index) => (
                        <div
                          key={item.nexus_user_id}
                          style={{
                            cursor: "pointer",
                            padding: "8px 16px",
                            boxSizing: "border-box",
                            borderRight: "4px solid transparent",
                            backgroundColor: !(index % 2 === 0)
                              ? "rgba(240,244,250,0.5)"
                              : "",
                          }}
                          onClick={() => selectAdmin(item.nexus_user_id)}
                        >
                          <div
                            class="sp-col"
                            style={{
                              flexGrow: 1,
                              minWidth: 0,
                              width: "100%",
                              position: "relative",
                              wordBreak: "break-word",
                            }}
                          >
                            <p class="sp-text">{`${item.displayname} (${item.username})`}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
            {/* Teams */}
            {answer.action == "default" && (
              <div style={{ marginTop: 8 }} class="dropdown sp-dropdown">
                <div
                  class="dropdown-button dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  <div class="text">
                    {answer && answer.assignData && answer.assignData.name}
                  </div>
                  <i class="icon-sort-down"></i>
                </div>
                <div class="dropdown-menu sp-list">
                  {assignList &&
                    _.values(assignList.teams).map((item, index) => (
                      <div
                        key={item.team_id}
                        style={{
                          cursor: "pointer",
                          padding: "8px 16px",
                          boxSizing: "border-box",
                          borderRight: "4px solid transparent",
                          backgroundColor: !(index % 2 === 0)
                            ? "rgba(240,244,250,0.5)"
                            : "",
                        }}
                        onClick={() => selectTeam(item.team_id)}
                      >
                        <div
                          class="sp-col"
                          style={{
                            flexGrow: 1,
                            minWidth: 0,
                            width: "100%",
                            position: "relative",
                            wordBreak: "break-word",
                          }}
                        >
                          <p class="sp-text">{`${item.name}`}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {assignList && (
          <div
            class="sp-row"
            style={{ alignItems: "flex-start", marginTop: 8 }}
          >
            <span
              class="sp-checkbox"
              style={{
                height: 22,
                width: 39,
                justifyContent: "center",
                marginRight: 0,
              }}
            >
              <input
                class="sp-input"
                type="checkbox"
                checked={selectChatbot}
                onClick={() => toggleChatbot(!selectChatbot)}
              />
            </span>
            <span class="sp-text" style={{ width: "30%" }}>
              {lang.__greeting_enable_chatbot}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
